.Header {
    position: fixed;
    z-index: 500;
    background-color: aliceblue;
    width: 100%;
    height: 10%
}
.topHeader {
    height: 100%;
}
.MuiPopover-paper {
    width: 40% !important;
    text-align: center;
}

.loginForm {
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 40px;
}
.items {
    text-align: center;
}
#start {
    margin-top: 20px;
    width: 25%;
    max-width: 60% !important;
   
  }

  #end {
    margin-top: 20px;
    width: 25%;
    max-width: 60% !important;
    float: right;
  }

  .pass {
    margin-left: 20px;
  }

  .error {
    color: red;
    font-weight: 400;
  }

  .messagecount {
    
    margin-left: -28px;
    margin-top: 0px;
    background-color: #22eba2ad;
    /* height: 40px; */
    /* width: 20px; */
    text-align: center;
    border-radius: 50%;
    width: 29px;
    height: 29px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    /* vertical-align: middle; */
    /* align-items: center; */
    align-items: center !important;
    justify-content: center;
  }